// make this a function so you can pass in a DIV name to support the ability to have multiple reports on a page

function resizeIFrameToFitContent(iFrame) {

    var reportContainer = document.getElementById('report-style-class');

    iFrame.width = reportContainer.clientWidth;
    iFrame.height = reportContainer.clientHeight;

    console.log("hi");

}

window.addEventListener('DOMContentLoaded', function (e)
{
    // powerbi.js doesnt give the embeeded iframe's an ID so we need to loop to find them.
    // assuming the only iframes that should be on any of our pages is the one we are embedding.
    var iframes = document.querySelectorAll("iframe");
    for (var i = 0; i < iframes.length; i++) {
        resizeIFrameToFitContent(iframes[i]);

        // PowerBI JavaScript adds "width:100%;height:100%;" in the style attribute which causes sizing issues. We'll style it from JavaScript and CSS. So we'll strip the inline style attribute from the iFrame.
        iframes[i].attributes.removeNamedItem("style");

        //alert(iframes[i].parentNode.id); // gets the parent div containing the iFrame. Can use this to make sure were re-rizing the right iFrame if we have multiple reports on one page.

    }
    });