import React from 'react';
import './header.css';
import logo from '../../assets/img/NAR_FullLockup_White.png';
import LogOutButton from '../login/logout.component';

export const AppHeader = () => (
  <div className="App-header">
    <div className="App-content">
      <div className="App-logo">
        <img src={logo} alt="NAR Logo" className="App-logo-img" />
      </div>
      <div className="App-top-nav">
        <ul className="App-top-navlist">
          <li>
            <div className="App-link">
              <a href="https://www.nar.realtor">Home</a>
            </div>
          </li>
          <li>
            <div className="App-link">
              <a href="https://www.nar.realtor/search-results">Search</a>
            </div>
          </li>
          <li>
            <div className="App-link">
              <a href="https://store.realtor/">Store</a>
            </div>
          </li>
          <li>
            <div className="App-link">
              <a href="https://www.nar.realtor/contact-us">Contact</a>
            </div>
          </li>
          <li>
            <div className="App-link">
              <a href="https://secure.realtor.org/ecommenu.nsf/ecommenu?OpenForm&Login">Pay Dues</a>
            </div>
          </li>
          <li><LogOutButton /></li>
        </ul>
      </div>
    </div>
  </div>
)
