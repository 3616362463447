import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// COMPONENTS
import { Loading } from './components/loading';
import { AppFooter } from './components/footer';
import { AppHeader } from './components/header';
// VIEWS
import Home from './views/home/home.view';
// API
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Authorized from './views/authorized/authorized.view';
import ReportApp from './views/report/report.view';

const theme = createTheme({
  palette: {
    primary: {
      light: '#007ed9',
      main: '#006BB7',
      dark: '#004282',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#61D19E',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});


const App = () => {
  const { isLoading } = false;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App-container" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <AppHeader />
      {/* <div className="App-row home-link">
        <a href='/'>Committee Selection Dashboard</a>
      </div> */}
      <div className='section-container' style={{ flexGrow: 1 }}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={ <Home/> } />
              <Route path="/authorized" exact element={ <Authorized/> } />
              <Route path="/report" exact element={ <ReportApp/> } />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </div>
      <AppFooter />
    </div>
    
  )
}


export default App;

