import '../../style.css';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { models, Report, Embed, service, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";

export default function App() {
  const [id, setId] = useState();
  const [embedUrl, setEmbedUrl] = useState();
  const [accessToken, setAccessToken] = useState();
  const [reportTitle, setReportTitle] = useState();

  const getData = async () => {
    const integrationKey = '';
    const { data } = await axios.get("https://dev.insightshub.realtor/InsightHubExternal/8f29ad50-3aab-47c7-942c-de50b9e9bc66/M1Report1");
    setId(data.Id);
    setEmbedUrl(data.EmbedUrl);
    setAccessToken(data.EmbedToken.token);
    setReportTitle(data.ReportName);
  };

  useEffect(() => {
    getData();
  }, []);

  return <div>

      <section>
       <div className='container'>
         <div className="App-content">
           <header className="App-title">
             <h1 className="page-title">
               NAR Committee Selection Dashboard
             </h1>
           </header>
           <div className="App-row">
             <h3 className='report-title'>{reportTitle}</h3>
             <div className="row col-12" style={{}}>
             <PowerBIEmbed
               embedConfig={{
                 type: "report",
                  id: id,
                 embedUrl: embedUrl,
                 accessToken: accessToken,
                 tokenType: models.TokenType.Embed,
                 settings: {
                   panes: {
                     filters: {
                       expanded: false,
                       visible: true,
                     },
                     pageNavigation: {
                       visible: true,
                     },
                   },
                 },
               }}
               eventHandlers={
                 new Map([
                   [
                     "loaded",
                     function () {
                       
                     },
                   ],
                   [
                     "rendered",
                     function () {
                       
                     },
                   ],
                   [
                     "error",
                   function (event) {
                       console.log(event.detail);
                     },
                   ],
                 ])
               }
               cssClassName={"report-style-class"}
               getEmbeddedComponent = { (embeddedReport) => {
                 window.report = embeddedReport;
               }}
            />
            </div>
          </div>
         </div>
       </div>
     </section>


  </div>;
}