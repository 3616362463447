import React from 'react';
import './footer.css';
import logo from '../../assets/img/NAR_FullLockup_White.png';

export const AppFooter = () => (
  <div className="App-footer">
    <div className="App-content">
      <div className="App-row">
        <table className="App-footer-table">
          <tbody>
          <tr>
            <td className="App-footer-table-td App-tf">
              <div className="App-footer-content">
                <span className="App-footer-title">Headquarters</span><br />
                430 N. Michigan Ave<br />
                Chicago, IL 60611-4087<br />
                800-874-6500<br />
              </div>
              <div className="App-footer-content">
                <span className="App-footer-title">DC Office</span><br />
                500 New Jersey Avenue, NW<br />
                Washington, D.C. 20001-2020<br />
                202-383-1000<br />
              </div>
            </td>
            <td className="App-footer-table-td">
              <div className="App-footer-content">
                <span className="App-footer-title">Contact Us</span><br />
                1.800.874.6500<br />
                Live Chat<br />
                Member Support is available Mon-Fri, 8am-5pm Central<br />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="App-row">
        <div className="App-footer-logo">
          <img src={logo} alt="NAR Logo" className="App-footer-logo-img" />
        </div>
        <div className="App-footer-content">
          © 2021 National Association of REALTORS®.  All Rights Reserved. <br />
          <img
            alt="NAR exclusive top-level domain."
            src="https://cdn.nar.realtor/sites/default/files/dotrealtor-wordmark-rgb-blue-22h.png"  />
          NAR's exclusive top-level domain.
        </div>
      </div>

    </div>
  </div>
)
