import '../../home.css';
import '../../style.css';
import '../../style.js';
import React, { useEffect, useState } from "react";
import { Loading } from '../../components/loading';
import axios from 'axios';
import { models, Report, Embed, service, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import {BrowserView, MobileView} from 'react-device-detect';

export default function App() {
  const [id, setId] = useState();
  const [embedUrl, setEmbedUrl] = useState();
  const [accessToken, setAccessToken] = useState();
  const [reportTitle, setReportTitle] = useState();
  const [reportPage, setReportPage] = useState();
  const [isUserAuth, setIsUserAuth] = useState();
  const [StaffFlag, setStaffFlag] = useState();
  const [loading, setLoading] = useState(true);

  const getData = async (authorization) => {
    const gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
    const { data } = await axios.get(gatewayUrl);
    setId(data.Id);
    setEmbedUrl(data.EmbedUrl);
    setAccessToken(data.EmbedToken.token);
    setReportTitle(data.ReportName);
    if(authorization == 'authorized') {
      setReportPage("ReportSection42c8b76ce3374e00b90e");
      setIsUserAuth(true);
    } else {
      setReportPage("ReportSection1807fc63e5647a4e4ee2");
      setIsUserAuth(true);
    }
  };

  useEffect(() => {
    axios.get("/profile")
      //.then(response => axios.get(`/api/validateUser?userNRSId=${response.data.nar_membership_id}`))
      .then(response => {
        if (response.data.nar_membership_type != "S") {
          setStaffFlag("NOT Staff")
          getData("unauthorized");
          setLoading(false);
        } else {
          setStaffFlag("Staff")
          getData("authorized");
          setLoading(false);
        }
      })
  }, []);

  return <div>
    {
      loading ? <Loading></Loading> : 
  			  isUserAuth ? (
            <section>
            <div className='container-fluid'>
              <div className="App-content">
                <header className="App-title">
                  <h1 className="page-title">
                    State and Local Leadership Directory
                  </h1>
                </header>
                <div className="App-row">
                  <div className="row col-12 reportDiv">
                  <BrowserView>
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',
                        id: id,
                        embedUrl: embedUrl,
                        accessToken: accessToken,
                        tokenType: models.TokenType.Embed,
                        pageName: reportPage,
                        settings: {
                          filterPaneEnabled: false,
                          navContentPaneEnabled: false,
                        },
                      }}
                      eventHandlers={
                        new Map([
                          [
                            "loaded",
                            function () {
                              
                            },
                          ],
                          [
                            "rendered",
                            function () {
                              
                            },
                          ],
                          [
                            "error",
                          function (event) {
                              console.log(event.detail);
                            },
                          ],
                        ])
                      }
                      cssClassName={"report-style-class"}
                      getEmbeddedComponent = { (embeddedReport) => {
                        window.report = embeddedReport;
                      }}
                    />
                  </BrowserView>
                  <MobileView>
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',
                        id: id,
                        embedUrl: embedUrl,
                        accessToken: accessToken,
                        tokenType: models.TokenType.Embed,
                        pageName: reportPage,
                        settings: {
                          filterPaneEnabled: false,
                          navContentPaneEnabled: false,
                          layoutType: models.LayoutType.MobilePortrait
                        },
                      }}
                      eventHandlers={
                        new Map([
                          [
                            "loaded",
                            function () {
                              
                            },
                          ],
                          [
                            "rendered",
                            function () {
                              
                            },
                          ],
                          [
                            "error",
                          function (event) {
                              console.log(event.detail);
                            },
                          ],
                        ])
                      }
                      cssClassName={"report-style-class-mobile"}
                      getEmbeddedComponent = { (embeddedReport) => {
                        window.report = embeddedReport;
                      }}
                    />
                  </MobileView>
                 </div>
               </div>
              </div>
            </div>
          </section>

                    ) : (
                      <section>
       <div className='container'>
        <h1>Loading...</h1>
       </div>
     </section>
                    )
 			}


  </div>;
}
