import '../../home.css';
import '../../style.css';
import React, { useEffect, useState } from "react";
import { Loading } from '../../components/loading';
import axios from 'axios';
import { models, Report, Embed, service, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";

export default function App() {
  const [id, setId] = useState();
  const [embedUrl, setEmbedUrl] = useState();
  const [accessToken, setAccessToken] = useState();
  const [reportTitle, setReportTitle] = useState();
  const [isUserAuth, setIsUserAuth] = useState();
  const [loading, setLoading] = useState(true);

  const getData = async (authorization) => {
    const gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
    if(authorization == 'authorized') {
      setIsUserAuth(true);
      const { data } = await axios.get(gatewayUrl);
      setId(data.Id);
      setEmbedUrl(data.EmbedUrl);
      setAccessToken(data.EmbedToken.token);
      setReportTitle(data.ReportName);
    } else {
      setIsUserAuth(false);
    }
  };

  // useEffect(() => {
  //   axios.get("/profile")
  //     .then(response => axios.get(`/api/validateUser?userNRSId=${response.data.nar_membership_id}`))
  //     .then(response => {
  //       if (response.data == null) {
  //         getData("unauthorized");
  //         setLoading(false);
  //       } else {
  //         getData("authorized");
  //         setLoading(false);
  //       }
  //     })
  // }, []);

  useEffect(() => {
    getData("authorized");
    setLoading(false);
  });

  return <div>
    {
      loading ? <Loading></Loading> : 
  			  isUserAuth ? (
            <section>
            <div className='container-fluid'>
              <div className="App-content">
                <header className="App-title">
                  <h1 className="page-title">
                    {reportTitle}
                  </h1>
                </header>
                <div className="App-row">
                  <div className="row col-12 reportDiv">
                  <PowerBIEmbed
                    embedConfig={{
                      type: "report",
                       id: id,
                      embedUrl: embedUrl,
                      accessToken: accessToken,
                      tokenType: models.TokenType.Embed,
                      settings: {
                        panes: {
                          filters: {
                            expanded: false,
                            visible: false,
                          },
                          pageNavigation: {
                            visible: true,
                          },
                        },
                      },
                    }}
                    eventHandlers={
                      new Map([
                        [
                          "loaded",
                          function () {
                            
                          },
                        ],
                        [
                          "rendered",
                          function () {
                            
                          },
                        ],
                        [
                          "error",
                        function (event) {
                            console.log(event.detail);
                          },
                        ],
                      ])
                    }
                    cssClassName={"report-style-class"}
                    getEmbeddedComponent = { (embeddedReport) => {
                      window.report = embeddedReport;
                    }}
                 />
                 </div>
               </div>
              </div>
            </div>
          </section>

                    ) : (
                      <section>
       <div className='container'>
         <div className="App-content">
           <header className="App-title">
             <h1 className="page-title">
               NAR State and Local Directories
             </h1>
           </header>
           <div className="App-row">
             <h3 className='report-title'>UNAUTHORIZED</h3>
          </div>
         </div>
       </div>
     </section>
                    )
 			}


  </div>;
}
